<template>
  <div>
    <vue-editor ref="editor" v-model="content" :options="editorOptions"></vue-editor>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import Vue2Editor from 'vue2-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css' // For default theme

Vue.use(Vue2Editor)

export default {
  props: {
    value:[String,null],
  },
  mounted(){
    setTimeout(() => {
      this.$set(this, 'content', this.value)
    }, 1000)

    // Handler Upload
    const quill = this.$refs.editor.quill // Access the Quill instance
    quill.getModule('toolbar').addHandler('image', () => {
      this.handleImage()
    })
  },
  data() {
    return {
      content: this.value,
      editorOptions: {
        theme: 'snow', // or 'bubble'
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean']
          ]
        }
      }
    }
  },
  methods: {
    handleImage(){
      const input = document.createElement('input')
      const quill = this.$refs.editor.quill

      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()

        formData.append('image', file)

        // Save current cursor state
        const range = quill.getSelection(true)

        // Insert temporary loading placeholder image
        quill.insertEmbed(range.index, 'image', `${ window.location.origin }/images/loaders/placeholder.gif`) 

        // Move cursor to right side of image (easier to continue typing)
        quill.setSelection(range.index + 1)

        const res = await axios.post(
          process.env.VUE_APP_API_URL + "/api/v1/app/ajax/upload?pageType=editor", 
          formData,
          {
            headers: {
              ...Gen.apiHeader(),
              'Content-Type': 'multipart/form-data'
            }
          }
        ) // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
        
        // Remove placeholder image
        quill.deleteText(range.index, 1)

        // Insert uploaded image
        let img = document.createElement('img')
        img.src = res.data.url
        img.alt = 'Image content'
        quill.clipboard.dangerouslyPasteHTML(range.index, img.outerHTML)
        // quill.insertEmbed(range.index, 'image', res.data.url) 
        // quill.formatLine(range.index, 1, 'alt', 'Image Content')
      }
    }
  },
  watch: {
    value(v){
      this.$set(this,'content',v)
    },
    content(v){
      this.$emit('update:value', v)
    }
  }
}
</script>